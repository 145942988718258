import React, { useState, useEffect } from 'react';
import { LoadingScreen } from './LoadingScreen';
import { initializeApplication } from '../utils/initialization';

interface AppProviderProps {
  children: React.ReactNode;
}

export const AppProvider: React.FC<AppProviderProps> = ({ children }) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const initialize = async () => {
      try {
        await initializeApplication();
        setIsInitialized(true);
      } catch (err) {
        console.error('Initialization error:', err);
        setError(err instanceof Error ? err : new Error('Failed to initialize application'));
      }
    };

    initialize();
  }, []);

  if (error) {
    throw error;
  }

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  return children;
};