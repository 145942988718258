import React from 'react';
import { X } from 'lucide-react';
import { format } from 'date-fns';
import { AnalysisEntry, ImpactLevel } from '../types/promise';
import { sortAnalysesByDate } from '../utils/impactCalculator';

interface HistoryModalProps {
  promiseId: number;
  promiseTitle: string;
  impactType: ImpactLevel;
  analyses: AnalysisEntry[];
  onClose: () => void;
}

export const HistoryModal: React.FC<HistoryModalProps> = ({
  promiseId,
  promiseTitle,
  impactType,
  analyses,
  onClose,
}) => {
  // Remove duplicates based on text and timestamp
  const uniqueAnalyses = analyses.filter((analysis, index, self) =>
    index === self.findIndex((a) => 
      a.text === analysis.text && 
      a.timestamp.toString() === analysis.timestamp.toString()
    )
  );
  
  const sortedAnalyses = sortAnalysesByDate(uniqueAnalyses);
  const filteredAnalyses = sortedAnalyses.filter(analysis => 
    analysis.results.some(result => 
      result.promiseId === promiseId && 
      result.impact === impactType
    )
  );

  const impactColor = {
    positive: 'text-green-400',
    negative: 'text-red-400',
    neutral: 'text-yellow-400'
  }[impactType];

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
      <div className="bg-gray-800 rounded-xl shadow-xl w-full max-w-4xl max-h-[80vh] flex flex-col">
        <div className="p-4 border-b border-gray-700 flex justify-between items-center">
          <div>
            <h2 className="text-xl font-bold text-white">{promiseTitle}</h2>
            <p className="text-sm text-gray-400">
              Historical <span className={`capitalize ${impactColor}`}>{impactType}</span> Analyses
            </p>
          </div>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-white transition-colors"
          >
            <X size={24} />
          </button>
        </div>
        
        <div className="overflow-auto flex-1 p-4">
          {filteredAnalyses.length === 0 ? (
            <p className="text-gray-400 text-center py-8">
              No {impactType} analyses found for this promise.
            </p>
          ) : (
            <div className="space-y-4">
              {filteredAnalyses.map((analysis, index) => {
                const result = analysis.results.find(r => 
                  r.promiseId === promiseId && r.impact === impactType
                );
                
                if (!result) return null;
                
                return (
                  <div 
                    key={`${analysis.text}-${analysis.timestamp}-${index}`}
                    className="bg-gray-900/50 rounded-lg p-4 border border-gray-700"
                  >
                    <div className="flex justify-between items-start mb-2">
                      <p className="text-gray-300">{analysis.text}</p>
                      <div className="flex flex-col items-end ml-4">
                        <span className="text-sm text-gray-500">
                          {format(new Date(analysis.timestamp), 'MMM d, yyyy HH:mm')}
                        </span>
                        <span className={`text-xs mt-1 ${analysis.classification === 'FACT' ? 'text-blue-400' : 'text-purple-400'}`}>
                          {analysis.classification || 'OPINION'}
                        </span>
                      </div>
                    </div>
                    <p className="text-sm text-gray-400 mt-2">
                      Rationale: {result.rationale}
                    </p>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};