import { validateConfig } from './config';
import { initializeFirebase } from './firebase';
import { initializeDatabase } from './db';
import { validateOpenAIConfig } from './openai';

export class InitializationError extends Error {
  constructor(message: string, public readonly cause?: Error) {
    super(message);
    this.name = 'InitializationError';
  }
}

export const initializeApplication = async () => {
  try {
    // Step 1: Validate all configurations
    validateConfig();
    validateOpenAIConfig();

    // Step 2: Initialize Firebase
    const firebase = await initializeFirebase();
    if (!firebase) {
      throw new InitializationError('Failed to initialize Firebase');
    }

    // Step 3: Initialize Database
    const dbInitialized = await initializeDatabase();
    if (!dbInitialized) {
      throw new InitializationError('Failed to initialize database');
    }

    return true;
  } catch (error) {
    if (error instanceof Error) {
      throw new InitializationError('Application initialization failed', error);
    }
    throw new InitializationError('Unexpected initialization error');
  }
};