import React from 'react';
import { BarChart2 } from 'lucide-react';
import { Menu } from './Menu';

interface HeaderProps {
  activeView: 'tracker' | 'history';
  onViewChange: (view: 'tracker' | 'history') => void;
}

export const Header: React.FC<HeaderProps> = ({ activeView, onViewChange }) => {
  return (
    <header className="mb-8">
      <div className="flex items-center justify-between gap-4 mb-4">
        {/* Logo and Title Container */}
        <div className="flex-1 flex items-center justify-center md:justify-start gap-2 md:gap-3">
          <BarChart2 className="w-6 h-6 md:w-8 md:h-8 text-blue-500 flex-shrink-0" />
          <h1 className="text-xl md:text-3xl font-bold text-white leading-tight">
            Trump Tracker
          </h1>
        </div>
        
        {/* Menu Button */}
        <div className="flex-shrink-0">
          <Menu activeView={activeView} onViewChange={onViewChange} />
        </div>
      </div>

      {/* Subtitle */}
      <h2 className="text-lg md:text-xl text-gray-400 text-center mb-6">
        An AI-Powered Tool To Hold the Trump Administration Accountable
      </h2>

      {/* Description Card */}
      <div className="max-w-3xl mx-auto bg-gray-800/50 p-4 md:p-6 rounded-xl border border-gray-700 shadow-lg">
        <p className="text-gray-300 text-center text-sm md:text-base">
          <b className="text-white block mb-2">
            Is President Trump on track to fulfill his campaign promises?
          </b>
          Easily analyze any online information to see how it aligns with Trump's 20 Agenda-47 promises. 
          Simply enter the text from an event or news you want to evaluate, and let our AI-powered tool show you how it impacts each of Trump's campaign promises.
        </p>
      </div>
    </header>
  );
};