import { ref, set, get, push, serverTimestamp } from 'firebase/database';
import { AnalysisEntry } from '../types/promise';
import { initializeFirebase } from './firebase';

const { db } = initializeFirebase();

export class DatabaseError extends Error {
  constructor(message: string, public readonly operation: string) {
    super(message);
    this.name = 'DatabaseError';
  }
}

export const saveAnalysis = async (entry: AnalysisEntry) => {
  try {
    // Create a single analysis entry with all data
    const analysisRef = ref(db, 'analyses');
    const newAnalysisRef = push(analysisRef);
    
    const analysisData = {
      text: entry.text,
      timestamp: entry.timestamp.toISOString(),
      classification: entry.classification,
      results: entry.results.map(result => ({
        promiseId: result.promiseId,
        impact: result.impact,
        rationale: result.rationale
      }))
    };

    // Save the complete analysis entry
    await set(newAnalysisRef, analysisData);

    // Update promise stats
    const statsPromises = entry.results.map(result => {
      const statsRef = ref(db, `promiseStats/${result.promiseId}`);
      return get(statsRef).then(snapshot => {
        const currentStats = snapshot.val() || {
          positive: 0,
          negative: 0,
          neutral: 0,
          facts: 0,
          opinions: 0,
          lastUpdated: null
        };

        currentStats[result.impact]++;
        if (entry.classification === 'FACT') {
          currentStats.facts++;
        } else {
          currentStats.opinions++;
        }
        currentStats.lastUpdated = entry.timestamp.toISOString();

        return set(statsRef, currentStats);
      });
    });

    await Promise.all(statsPromises);
    return true;
  } catch (error) {
    console.error('Failed to save analysis:', error);
    throw new DatabaseError('Failed to save analysis', 'saveAnalysis');
  }
};

export const getAnalyses = async () => {
  try {
    const analysesRef = ref(db, 'analyses');
    const snapshot = await get(analysesRef);
    
    if (!snapshot.exists()) {
      return [];
    }

    const analyses = Object.entries(snapshot.val()).map(([id, data]: [string, any]) => ({
      id,
      text: data.text,
      timestamp: new Date(data.timestamp),
      classification: data.classification || 'OPINION',
      results: data.results.map(result => ({
        ...result,
        timestamp: new Date(data.timestamp)
      }))
    }));

    return analyses.sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime());
  } catch (error) {
    console.error('Failed to get analyses:', error);
    throw new DatabaseError('Failed to retrieve analyses', 'getAnalyses');
  }
};

export const loadPromiseStats = async () => {
  try {
    const statsRef = ref(db, 'promiseStats');
    const snapshot = await get(statsRef);
    
    if (!snapshot.exists()) {
      return {};
    }
    
    return snapshot.val();
  } catch (error) {
    console.error('Failed to load promise stats:', error);
    throw new DatabaseError('Failed to load promise statistics', 'loadPromiseStats');
  }
};

export const initializeDatabase = async () => {
  try {
    const statsRef = ref(db, 'promiseStats');
    const snapshot = await get(statsRef);
    
    if (!snapshot.exists()) {
      const initialStats = {};
      for (let i = 1; i <= 20; i++) {
        initialStats[i] = {
          positive: 0,
          negative: 0,
          neutral: 0,
          facts: 0,
          opinions: 0,
          lastUpdated: new Date().toISOString()
        };
      }
      await set(statsRef, initialStats);
    }
    return true;
  } catch (error) {
    console.error('Database initialization error:', error);
    throw new DatabaseError('Failed to initialize database', 'initializeDatabase');
  }
};

export const clearDatabase = async () => {
  try {
    await set(ref(db, 'analyses'), null);

    const initialStats = {};
    for (let i = 1; i <= 20; i++) {
      initialStats[i] = {
        positive: 0,
        negative: 0,
        neutral: 0,
        facts: 0,
        opinions: 0,
        lastUpdated: new Date().toISOString()
      };
    }
    await set(ref(db, 'promiseStats'), initialStats);
    
    return true;
  } catch (error) {
    console.error('Failed to clear database:', error);
    throw new DatabaseError('Failed to clear database', 'clearDatabase');
  }
};

// Initialize database
initializeDatabase().catch(error => {
  console.error('Failed to initialize database:', error);
});