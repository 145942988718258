import React, { useState } from 'react';
import { Menu as MenuIcon, X, History, BarChart2 } from 'lucide-react';

interface MenuProps {
  activeView: 'tracker' | 'history';
  onViewChange: (view: 'tracker' | 'history') => void;
}

export const Menu: React.FC<MenuProps> = ({ activeView, onViewChange }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleViewChange = (view: 'tracker' | 'history') => {
    onViewChange(view);
    setIsOpen(false);
  };

  return (
    <div className="relative">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="p-2 text-gray-400 hover:text-white transition-colors rounded-lg hover:bg-gray-800"
        aria-label="Menu"
      >
        {isOpen ? <X size={20} /> : <MenuIcon size={20} />}
      </button>

      {isOpen && (
        <>
          <div 
            className="fixed inset-0 bg-black/50 backdrop-blur-sm z-40"
            onClick={() => setIsOpen(false)}
          />
          <div className="absolute right-0 top-full mt-2 w-56 rounded-lg bg-gray-800 border border-gray-700 shadow-lg z-50">
            <div className="p-2">
              <button
                onClick={() => handleViewChange('tracker')}
                className={`w-full flex items-center gap-2 px-4 py-2 rounded-lg text-left transition-colors ${
                  activeView === 'tracker'
                    ? 'bg-blue-600 text-white'
                    : 'text-gray-300 hover:bg-gray-700'
                }`}
              >
                <BarChart2 size={18} />
                View Promises
              </button>
              <button
                onClick={() => handleViewChange('history')}
                className={`w-full flex items-center gap-2 px-4 py-2 rounded-lg text-left transition-colors ${
                  activeView === 'history'
                    ? 'bg-blue-600 text-white'
                    : 'text-gray-300 hover:bg-gray-700'
                }`}
              >
                <History size={18} />
                View History
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};