import React, { useState, useEffect } from 'react';
import { Toaster, toast } from 'react-hot-toast';
import { Header } from './components/Header';
import { TrackerView } from './components/TrackerView';
import { TextAnalyzer } from './components/TextAnalyzer';
import { AnalysisTable } from './components/AnalysisTable';
import { Footer } from './components/Footer';
import { SubmissionsHistory } from './components/SubmissionsHistory';
import { promisesData } from './data/promiseData';
import { AnalysisResult, PromiseData, ImpactLevel, PromiseCategory } from './types/promise';
import { saveAnalysis, loadPromiseStats } from './utils/db';

function App() {
  const [activeView, setActiveView] = useState<'tracker' | 'history'>('tracker');
  const [promises, setPromises] = useState<PromiseData[]>(promisesData);
  const [analysisResults, setAnalysisResults] = useState<AnalysisResult[] | null>(null);
  const [lastAnalyzedText, setLastAnalyzedText] = useState<string>('');
  const [highlightedPromises, setHighlightedPromises] = useState<Record<number, ImpactLevel>>({});
  const [selectedCategories, setSelectedCategories] = useState<PromiseCategory[]>([]);

  useEffect(() => {
    const loadStats = async () => {
      try {
        const stats = await loadPromiseStats();
        if (stats) {
          const updatedPromises = promises.map(promise => ({
            ...promise,
            positive: stats[promise.id]?.positive || 0,
            negative: stats[promise.id]?.negative || 0,
            neutral: stats[promise.id]?.neutral || 0,
            facts: stats[promise.id]?.facts || 0,
            opinions: stats[promise.id]?.opinions || 0,
            lastUpdated: stats[promise.id]?.lastUpdated
          }));
          setPromises(updatedPromises);
        }
      } catch (error) {
        console.error('Failed to load promise stats:', error);
        toast.error('Failed to load promise statistics');
      }
    };

    loadStats();
  }, []);

  const handleAnalysis = async (text: string, results: AnalysisResult[], classification: string) => {
    try {
      const timestamp = new Date();
      const newHighlights: Record<number, ImpactLevel> = {};
      const updatedPromises = [...promises];
      
      results.forEach(result => {
        newHighlights[result.promiseId] = result.impact;
        const promise = updatedPromises.find(p => p.id === result.promiseId);
        if (promise) {
          if (result.impact === 'positive') promise.positive++;
          else if (result.impact === 'negative') promise.negative++;
          else promise.neutral++;
          
          if (classification === 'FACT') promise.facts++;
          else promise.opinions++;
          
          promise.lastUpdated = timestamp;
        }
      });

      await saveAnalysis({
        text,
        timestamp,
        classification,
        results
      });
      
      setPromises(updatedPromises);
      setHighlightedPromises(newHighlights);
      setAnalysisResults(results);
      setLastAnalyzedText(text);
      
    } catch (error) {
      console.error('Analysis failed:', error);
      throw error;
    }
  };

  const handleShare = (id: number) => {
    const promise = promises.find(p => p.id === id);
    if (!promise) return;

    const baseUrl = window.location.origin;
    const slug = promise.title
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, '-')
      .replace(/^-+|-+$/g, '');
    
    const url = `${baseUrl}/#${slug}`;

    navigator.clipboard.writeText(url)
      .then(() => {
        toast.success('Link copied to clipboard!', {
          duration: 2000,
          icon: '🔗'
        });
      })
      .catch(() => {
        toast.error('Failed to copy link');
      });
  };

  const handleToggleCategory = (category: PromiseCategory) => {
    setSelectedCategories(prev => 
      prev.includes(category)
        ? prev.filter(c => c !== category)
        : [...prev, category]
    );
  };

  const handleDismissResults = () => {
    setAnalysisResults(null);
    setLastAnalyzedText('');
    setHighlightedPromises({});
  };

  return (
    <div className="min-h-screen bg-gray-900 py-12 px-4 pb-20">
      <div className="max-w-6xl mx-auto">
        <Header activeView={activeView} onViewChange={setActiveView} />
        
        {activeView === 'tracker' && (
          <>
            <TextAnalyzer onAnalysis={handleAnalysis} />
            
            {analysisResults && analysisResults.length > 0 && (
              <div className="mb-8">
                <AnalysisTable
                  results={analysisResults}
                  onDismiss={handleDismissResults}
                  userText={lastAnalyzedText}
                />
              </div>
            )}
            
            <TrackerView 
              promises={promises}
              selectedCategories={selectedCategories}
              onToggleCategory={handleToggleCategory}
              highlightedPromises={highlightedPromises}
              onShare={handleShare}
            />
          </>
        )}

        {activeView === 'history' && (
          <SubmissionsHistory onBack={() => setActiveView('tracker')} />
        )}
      </div>
      
      <Footer />
      
      <Toaster 
        position="bottom-center"
        toastOptions={{
          style: {
            background: '#374151',
            color: '#fff',
          },
        }}
      />
    </div>
  );
}

export default App;