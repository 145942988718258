import React from 'react';
import { PromiseCategory } from '../types/promise';
import { Shield, TrendingUp, Heart, Users } from 'lucide-react';

interface CategoryFiltersProps {
  selectedCategories: PromiseCategory[];
  onToggleCategory: (category: PromiseCategory) => void;
}

const categoryConfig: Record<PromiseCategory, { icon: React.ReactNode; color: string }> = {
  'Security': { 
    icon: <Shield className="w-4 h-4" />, 
    color: 'bg-blue-900/30 border-blue-800/30 text-blue-300 hover:bg-blue-900/40'
  },
  'Prosperity': { 
    icon: <TrendingUp className="w-4 h-4" />, 
    color: 'bg-green-900/30 border-green-800/30 text-green-300 hover:bg-green-900/40'
  },
  'Cultural Issues': { 
    icon: <Heart className="w-4 h-4" />, 
    color: 'bg-purple-900/30 border-purple-800/30 text-purple-300 hover:bg-purple-900/40'
  },
  'National Unity': { 
    icon: <Users className="w-4 h-4" />, 
    color: 'bg-orange-900/30 border-orange-800/30 text-orange-300 hover:bg-orange-900/40'
  }
};

export const CategoryFilters: React.FC<CategoryFiltersProps> = ({
  selectedCategories,
  onToggleCategory,
}) => {
  return (
    <div className="mb-8">
      <h3 className="text-base text-gray-400 mb-3 ml-2">Promise Categories</h3>
      <div className="flex flex-wrap gap-2 justify-center">
        {(Object.keys(categoryConfig) as PromiseCategory[]).map((category) => {
          const isSelected = selectedCategories.includes(category);
          const config = categoryConfig[category];
          
          return (
            <button
              key={category}
              onClick={() => onToggleCategory(category)}
              className={`
                flex items-center gap-2 px-4 py-2 rounded-full border transition-all
                ${config.color}
                ${isSelected ? 'ring-2 ring-offset-2 ring-offset-gray-900 ring-current' : 'opacity-75 hover:opacity-100'}
              `}
            >
              {config.icon}
              <span className="text-sm font-medium">{category}</span>
            </button>
          );
        })}
      </div>
    </div>
  );
};