import React from 'react';
import { X } from 'lucide-react';
import { AnalysisTableProps } from '../types/promise';
import { promisesData } from '../data/promiseData';

export const AnalysisTable: React.FC<AnalysisTableProps> = ({ results, onDismiss, userText }) => {
  if (!results || results.length === 0) {
    return (
      <div className="bg-gray-800 rounded-xl p-6 text-center border border-gray-700 analysis-results">
        <h2 className="text-xl font-bold text-white mb-4">Analysis Complete</h2>
        <p className="text-gray-300 mb-6">
          No significant impacts were found for any promises.
        </p>
        <button
          onClick={onDismiss}
          className="bg-blue-600 text-white px-6 py-2 rounded-lg hover:bg-blue-700 transition-colors"
        >
          Close
        </button>
      </div>
    );
  }

  const getPromiseTitle = (promiseId: number) => {
    const promise = promisesData.find(p => p.id === promiseId);
    return promise?.title.replace(/^#\d+:\s*/, '') || 'Unknown Promise';
  };

  return (
    <div className="bg-gray-800 rounded-xl shadow-xl border border-gray-700 analysis-results">
      <div className="p-4 border-b border-gray-700 flex justify-between items-start">
        <div>
          <h2 className="text-xl font-bold text-white mb-2">Analysis Results</h2>
          <p className="text-gray-400 text-sm">
            Analyzed text: <span className="text-gray-300">{userText}</span>
          </p>
        </div>
        <button
          onClick={onDismiss}
          className="text-gray-400 hover:text-white transition-colors"
        >
          <X size={24} />
        </button>
      </div>
      
      <div className="p-4">
        <div className="space-y-4">
          {results.map((result, index) => (
            <div
              key={`${result.promiseId}-${index}`}
              className="bg-gray-900/50 rounded-lg p-4 border border-gray-700"
            >
              <div className="flex items-start justify-between gap-4">
                <div className="flex-1">
                  <h3 className="font-semibold text-white mb-2">
                    Promise #{result.promiseId}: {getPromiseTitle(result.promiseId)}
                  </h3>
                  <p className="text-gray-300 text-sm">{result.rationale}</p>
                </div>
                <div className={`px-3 py-1 rounded-full text-sm font-medium ${
                  result.impact === 'positive' 
                    ? 'bg-green-900/30 text-green-400 border border-green-800/30'
                    : 'bg-red-900/30 text-red-400 border border-red-800/30'
                }`}>
                  {result.impact === 'positive' ? 'Positive' : 'Negative'}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};