import React, { useState } from 'react';
import { Send } from 'lucide-react';
import { toast } from 'react-hot-toast';
import { analyzeTextWithAI } from '../utils/openai';
import { AnalysisResult } from '../types/promise';
import { LoadingSpinner } from './LoadingSpinner';

interface TextAnalyzerProps {
  onAnalysis: (text: string, results: AnalysisResult[], classification: string) => void;
}

export const TextAnalyzer: React.FC<TextAnalyzerProps> = ({ onAnalysis }) => {
  const [text, setText] = useState('');
  const [isAnalyzing, setIsAnalyzing] = useState(false);

  const handleAnalyze = async () => {
    const trimmedText = text.trim();
    if (!trimmedText) {
      toast.error('Please enter some text to analyze');
      return;
    }

    // Check for minimum text length
    if (trimmedText.length < 10) {
      toast.error('Please enter more detailed text for analysis');
      return;
    }

    if (isAnalyzing) {
      return;
    }

    setIsAnalyzing(true);

    try {
      const { results, classification } = await analyzeTextWithAI(trimmedText);
      onAnalysis(trimmedText, results, classification);
      setText('');
      toast.success('Analysis complete!');

      setTimeout(() => {
        const analysisTable = document.querySelector('.analysis-results');
        if (analysisTable) {
          analysisTable.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }, 100);
    } catch (error) {
      console.error('Analysis failed:', error);
      
      if (error.name === 'OpenAIError') {
        switch (error.code) {
          case 'INSUFFICIENT_TEXT':
            toast.error('Please provide more detailed text for analysis');
            break;
          case 'NO_IMPACTS':
            toast.error('No significant impacts found. Please provide text with specific policy actions or outcomes.');
            break;
          case 'RATE_LIMIT':
            toast.error('Too many requests. Please wait a moment and try again.');
            break;
          case 'AUTH_ERROR':
            toast.error('Authentication error. Please try again later.');
            break;
          case 'PARSE_ERROR':
            toast.error('Error processing response. Please try again.');
            break;
          default:
            toast.error(error.message || 'Analysis failed. Please try again.');
        }
      } else {
        toast.error('An unexpected error occurred. Please try again.');
      }
    } finally {
      setIsAnalyzing(false);
    }
  };

  return (
    <div className="w-full mb-8">
      <div className="relative bg-gray-800/50 p-6 rounded-xl border border-gray-700 shadow-lg">
        <label htmlFor="analysis-input" className="block text-lg font-semibold text-white mb-2">
          Analyze Text
        </label>
        <p className="text-gray-400 text-sm mb-4">
          Enter any text or news article to see how it impacts Trump's promises...
        </p>
        <div className="relative">
          <textarea
            id="analysis-input"
            value={text}
            onChange={(e) => setText(e.target.value)}
            placeholder="Paste your text here..."
            className="w-full px-4 py-3 bg-gray-800 border-2 border-gray-600 rounded-lg text-white placeholder-gray-500 focus:ring-2 focus:ring-blue-500 focus:border-transparent resize-none h-32 transition-all duration-200"
            disabled={isAnalyzing}
          />
          <button
            onClick={handleAnalyze}
            disabled={!text.trim() || isAnalyzing}
            className="absolute bottom-3 right-3 p-2 bg-blue-600 text-white rounded-full disabled:opacity-50 disabled:cursor-not-allowed hover:bg-blue-700 transition-colors"
          >
            <Send size={20} />
          </button>
          {isAnalyzing && <LoadingSpinner />}
        </div>
      </div>
    </div>
  );
};