import React from 'react';
import { PromiseCard } from './PromiseCard';
import { CategoryFilters } from './CategoryFilters';
import { PromiseCategory, PromiseData, ImpactLevel } from '../types/promise';

interface TrackerViewProps {
  promises: PromiseData[];
  selectedCategories: PromiseCategory[];
  onToggleCategory: (category: PromiseCategory) => void;
  highlightedPromises: Record<number, ImpactLevel>;
  onShare: (id: number) => void;
}

export const TrackerView: React.FC<TrackerViewProps> = ({
  promises,
  selectedCategories,
  onToggleCategory,
  highlightedPromises,
  onShare,
}) => {
  const filteredPromises = promises.filter(promise => 
    selectedCategories.length === 0 || selectedCategories.includes(promise.category)
  );

  return (
    <div>
      <CategoryFilters 
        selectedCategories={selectedCategories}
        onToggleCategory={onToggleCategory}
      />
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {filteredPromises.map((promise) => (
          <PromiseCard
            key={promise.id}
            data={promise}
            onShare={() => onShare(promise.id)}
            highlight={highlightedPromises[promise.id]}
          />
        ))}
      </div>
    </div>
  );
};