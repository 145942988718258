import { initializeApp, FirebaseApp } from 'firebase/app';
import { Database, getDatabase } from 'firebase/database';
import { validateFirebaseConfig } from './config';

interface FirebaseInstance {
  app: FirebaseApp;
  db: Database;
}

let instance: FirebaseInstance | null = null;

export const initializeFirebase = (): FirebaseInstance => {
  if (instance) return instance;

  const config = validateFirebaseConfig();
  
  try {
    const app = initializeApp(config);
    const db = getDatabase(app);
    
    instance = { app, db };
    return instance;
  } catch (error) {
    console.error('Failed to initialize Firebase:', error);
    throw new Error('Failed to initialize Firebase. Please check your configuration.');
  }
};