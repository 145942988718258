import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { getAnalyses } from '../utils/db';
import { AnalysisEntry } from '../types/promise';
import { Loader2, BarChart2 } from 'lucide-react';
import { promisesData } from '../data/promiseData';
import { toast } from 'react-hot-toast';

interface SubmissionsHistoryProps {
  onBack?: () => void;
}

export const SubmissionsHistory: React.FC<SubmissionsHistoryProps> = ({ onBack }) => {
  const [submissions, setSubmissions] = useState<AnalysisEntry[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    let mounted = true;

    const loadSubmissions = async () => {
      try {
        setLoading(true);
        setError(null);
        const analyses = await getAnalyses();
        
        if (mounted) {
          setSubmissions(analyses);
        }
      } catch (err) {
        if (mounted) {
          const message = 'Failed to load submission history. Please try again.';
          setError(message);
          toast.error(message);
        }
      } finally {
        if (mounted) {
          setLoading(false);
        }
      }
    };

    loadSubmissions();

    return () => {
      mounted = false;
    };
  }, []);

  const getPromiseTitle = (promiseId: number) => {
    const promise = promisesData.find(p => p.id === promiseId);
    return promise?.title.replace(/^#\d+:\s*/, '') || 'Unknown Promise';
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center p-8">
        <Loader2 className="w-8 h-8 text-blue-500 animate-spin" />
      </div>
    );
  }

  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-xl font-bold text-white">Submission History</h2>
        <button
          onClick={onBack}
          className="flex items-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
        >
          <BarChart2 className="w-5 h-5" />
          Back to Promises
        </button>
      </div>

      {error ? (
        <div className="bg-red-900/20 border border-red-800/30 text-red-400 p-4 rounded-lg">
          {error}
        </div>
      ) : submissions.length === 0 ? (
        <div className="bg-gray-800 rounded-xl p-8 text-center border border-gray-700">
          <p className="text-gray-400">No submissions found</p>
        </div>
      ) : (
        <div className="space-y-4">
          {submissions.map((submission, index) => (
            <div
              key={`${submission.id || submission.timestamp.toISOString()}-${index}`}
              className="bg-gray-800 rounded-xl p-6 border border-gray-700"
            >
              <div className="flex justify-between items-start gap-4 mb-4">
                <div className="flex-1">
                  <p className="text-gray-300">{submission.text}</p>
                </div>
                <div className="flex flex-col items-end">
                  <span className="text-sm text-gray-500">
                    {format(submission.timestamp, 'MMM d, yyyy HH:mm')}
                  </span>
                  <span className={`text-xs mt-1 px-2 py-1 rounded-full ${
                    submission.classification === 'FACT'
                      ? 'bg-blue-900/30 text-blue-400 border border-blue-800/30'
                      : 'bg-purple-900/30 text-purple-400 border border-purple-800/30'
                  }`}>
                    {submission.classification}
                  </span>
                </div>
              </div>
              <div className="space-y-2">
                {submission.results
                  .filter(result => result.impact !== 'neutral')
                  .map((result, idx) => (
                    <div
                      key={idx}
                      className="text-sm bg-gray-900/50 rounded-lg p-3 border border-gray-700"
                    >
                      <div className="flex flex-col gap-1">
                        <div className="flex items-center justify-between gap-2">
                          <span className="text-gray-300 font-medium">
                            Promise #{result.promiseId}: {getPromiseTitle(result.promiseId)}
                          </span>
                          <span className={`px-2 py-0.5 rounded-full text-xs font-medium ${
                            result.impact === 'positive'
                              ? 'bg-green-900/30 text-green-400 border border-green-800/30'
                              : 'bg-red-900/30 text-red-400 border border-red-800/30'
                          }`}>
                            {result.impact.charAt(0).toUpperCase() + result.impact.slice(1)}
                          </span>
                        </div>
                        <p className="text-gray-400">{result.rationale}</p>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};