import { AnalysisEntry } from '../types/promise';

const FACT_WEIGHT = 2;
const OPINION_WEIGHT = 1;

export const calculateWeightedImpact = (
  positive: number,
  negative: number,
  facts: number,
  opinions: number
): 'positive' | 'negative' | 'neutral' => {
  // Calculate weighted scores
  const weightedPositive = (positive * FACT_WEIGHT * (facts / (facts + opinions))) +
                          (positive * OPINION_WEIGHT * (opinions / (facts + opinions)));
  
  const weightedNegative = (negative * FACT_WEIGHT * (facts / (facts + opinions))) +
                          (negative * OPINION_WEIGHT * (opinions / (facts + opinions)));

  if (weightedPositive > weightedNegative) return 'positive';
  if (weightedNegative > weightedPositive) return 'negative';
  return 'neutral';
};

export const sortAnalysesByDate = (analyses: AnalysisEntry[]): AnalysisEntry[] => {
  return [...analyses].sort((a, b) => {
    const dateA = new Date(a.timestamp);
    const dateB = new Date(b.timestamp);
    return dateB.getTime() - dateA.getTime();
  });
};