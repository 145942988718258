import React from 'react';
import { PromiseData } from '../types/promise';
import { calculateWeightedImpact } from '../utils/impactCalculator';

interface StatusPillProps {
  data: PromiseData;
  className?: string;
}

export const StatusPill: React.FC<StatusPillProps> = ({ data, className = '' }) => {
  const { positive, negative, facts, opinions } = data;
  
  if (positive === 0 && negative === 0) {
    return (
      <span className={`px-2.5 py-1 rounded-full text-xs font-medium bg-gray-800 text-gray-400 border border-gray-700 ${className}`}>
        No Data
      </span>
    );
  }
  
  const status = calculateWeightedImpact(positive, negative, facts, opinions);
  const statusText = {
    positive: 'On Track',
    negative: 'Off Track',
    neutral: 'No Progress'
  }[status];
  
  const colors = {
    positive: 'bg-green-900/30 text-green-400 border-green-800/30',
    negative: 'bg-red-900/30 text-red-400 border-red-800/30',
    neutral: 'bg-yellow-900/30 text-yellow-400 border-yellow-800/30'
  }[status];
  
  return (
    <span className={`px-2.5 py-1 rounded-full text-xs font-medium ${colors} border ${className}`}>
      {statusText}
    </span>
  );
};