import React, { Component, ErrorInfo, ReactNode } from 'react';
import { AlertTriangle } from 'lucide-react';
import { ConfigurationError } from '../utils/config';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
  error: Error | null;
}

export class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
    error: null
  };

  public static getDerivedStateFromError(error: Error): State {
    return { hasError: true, error };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);
  }

  private getErrorMessage(error: Error | null): string {
    if (!error) return 'An unexpected error occurred';

    if (error instanceof ConfigurationError) {
      return `Configuration Error: ${error.message}`;
    }

    if (error.message.includes('Firebase')) {
      return 'Firebase initialization failed. Please check your configuration.';
    }

    return error.message;
  }

  private getErrorAction(error: Error | null): string {
    if (error instanceof ConfigurationError) {
      return 'Please check your environment variables and try again.';
    }
    return 'Try reloading the page or contact support if the problem persists.';
  }

  public render() {
    if (this.state.hasError) {
      return (
        <div className="min-h-screen bg-gray-900 flex items-center justify-center p-4">
          <div className="bg-gray-800 rounded-xl p-8 max-w-lg w-full border border-gray-700">
            <div className="flex items-center gap-3 mb-6">
              <AlertTriangle className="w-8 h-8 text-red-500" />
              <h2 className="text-2xl font-bold text-white">Something went wrong</h2>
            </div>
            <p className="text-gray-300 mb-4">
              {this.getErrorMessage(this.state.error)}
            </p>
            <p className="text-gray-400 text-sm mb-6">
              {this.getErrorAction(this.state.error)}
            </p>
            <button
              onClick={() => window.location.reload()}
              className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition-colors w-full"
            >
              Reload Application
            </button>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}