import OpenAI from 'openai';
import { AnalysisResult } from '../types/promise';
import { promisesData } from '../data/promiseData';

export class OpenAIError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'OpenAIError';
  }
}

export const validateOpenAIConfig = () => {
  const apiKey = import.meta.env.VITE_OPENAI_API_KEY;
  
  if (!apiKey) {
    throw new OpenAIError('OpenAI API key not configured');
  }

  if (!apiKey.startsWith('sk-') || apiKey.length < 40) {
    throw new OpenAIError('Invalid OpenAI API key format');
  }

  return apiKey;
};

const getOpenAIClient = () => {
  const apiKey = validateOpenAIConfig();
  return new OpenAI({
    apiKey,
    dangerouslyAllowBrowser: true
  });
};

export const analyzeTextWithAI = async (text: string) => {
  try {
    const openai = getOpenAIClient();

    const systemPrompt = `You are a strictly neutral policy analyst focused on objective evaluation of political promises and their implementation. Your analysis must:

1. Maintain absolute political neutrality
2. Focus exclusively on verifiable facts and measurable outcomes
3. Evaluate based on concrete actions and documented evidence
4. Consider multiple perspectives equally
5. Avoid any ideological bias or partisan interpretation
6. Use consistent evaluation criteria across all analyses
7. Separate factual reporting from opinion/speculation
8. Base conclusions solely on evidence, not rhetoric
9. Acknowledge complexity and uncertainty when present
10. Evaluate implementation progress objectively`;

    const userPrompt = `Analyze this text objectively: "${text}"

First, classify this text as either:
- FACT: Verifiable information from official sources, documented events, or concrete actions
- OPINION: Interpretations, predictions, analysis, or commentary

Then, analyze how this text relates to Trump's campaign promises using these strict criteria:

POSITIVE impact - Must have AT LEAST ONE of:
- Concrete policy implementation
- Verifiable progress metrics
- Official actions taken
- Resource allocation
- Legal/regulatory changes
- Documented results

NEGATIVE impact - Must have AT LEAST ONE of:
- Documented setbacks
- Policy reversals
- Implementation barriers
- Resource limitations
- Legal obstacles
- Measurable challenges

NEUTRAL impact if:
- No clear connection exists
- Evidence is insufficient
- Impact is unclear or mixed
- Only speculative connections

Promises to analyze:
${promisesData.map(p => `${p.id}. ${p.title.replace(/^#\d+:\s*/, '')}: ${p.description}`).join('\n')}

Respond in this exact JSON format:
{
  "classification": "FACT or OPINION",
  "results": [
    {
      "promiseId": number,
      "impact": "positive" | "negative" | "neutral",
      "rationale": "Brief, evidence-based explanation focusing on concrete actions/outcomes"
    }
  ]
}`;

    const response = await openai.chat.completions.create({
      model: "gpt-3.5-turbo-0125",
      messages: [
        {
          role: "system",
          content: systemPrompt
        },
        {
          role: "user",
          content: userPrompt
        }
      ],
      temperature: 0.1,
      max_tokens: 2000,
      response_format: { type: "json_object" }
    });

    const content = response.choices[0]?.message?.content;
    if (!content) {
      throw new OpenAIError('No response received from OpenAI');
    }

    try {
      const parsedResponse = JSON.parse(content);
      
      if (!parsedResponse.classification || !Array.isArray(parsedResponse.results)) {
        throw new OpenAIError('Invalid response format from OpenAI');
      }

      // Validate and filter results
      const validResults = parsedResponse.results.filter(result => {
        const isValidPromise = promisesData.some(p => p.id === result.promiseId);
        const isValidImpact = ['positive', 'negative', 'neutral'].includes(result.impact);
        const hasRationale = Boolean(result.rationale?.trim());
        const isSignificant = result.impact !== 'neutral';
        
        return isValidPromise && isValidImpact && hasRationale && isSignificant;
      });

      if (validResults.length === 0) {
        throw new OpenAIError('No significant impacts found');
      }

      return {
        classification: parsedResponse.classification,
        results: validResults.map(result => ({
          ...result,
          timestamp: new Date()
        }))
      };
    } catch (parseError) {
      console.error('Failed to parse OpenAI response:', parseError);
      throw new OpenAIError('Failed to parse OpenAI response');
    }
  } catch (error) {
    console.error('OpenAI analysis failed:', error);
    
    if (error instanceof OpenAIError) {
      throw error;
    }
    
    if (error.response?.status === 401) {
      throw new OpenAIError('Invalid OpenAI API key');
    }

    if (error.response?.status === 429) {
      throw new OpenAIError('Rate limit exceeded. Please try again later.');
    }
    
    throw new OpenAIError(error.message || 'Failed to analyze text');
  }
};