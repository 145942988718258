import React from 'react';
import { Twitter, ArrowUp } from 'lucide-react';

export const Footer: React.FC = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <footer className="fixed bottom-0 left-0 right-0 bg-gray-800/80 backdrop-blur-sm border-t border-gray-700 py-3 px-4 z-10">
      <div className="max-w-6xl mx-auto flex justify-between items-center mr-20">
        <button
          onClick={scrollToTop}
          className="flex items-center gap-2 text-gray-400 hover:text-white transition-colors group"
          aria-label="Back to top"
        >
          <ArrowUp className="w-4 h-4 transition-transform group-hover:-translate-y-0.5" />
          <span className="text-sm">Back to top</span>
        </button>
        
        <a
          href="https://x.com/trumptrackerio"
          target="_blank"
          rel="noopener noreferrer"
          className="text-gray-400 hover:text-white transition-colors"
          aria-label="Follow us on X (Twitter)"
        >
          <Twitter className="w-5 h-5" />
        </a>
      </div>
    </footer>
  );
};